<template>
    
	<div class="grid dashboard">
            <div class="col-12 sm:col-12 md:col-6 lg:col-3"> 
                <div class="card overview-box flex flex-column pt-2 height-90"> 
                    <div class="flex justify-content-between mt-4 flex-wrap"> 
                        <div class="digital-clock-widget" >
                            <DigitalClock :fancy="true"/>
                        </div> 
                        <Divider />
                            <div class="field full-width"> 
                                <span class="p-float-label">
                                    <InputText type="text" @keyup.enter="searchApplication" v-model="applicationId" id="applicationID" class="full-width" :autofocus="true" />
                                    <label for="applicationID">Application ID</label>  
                                </span>
                            </div> 
                            <Button @click="searchApplication" v-tooltip.top="'Search Application'" :label="showBtnLabels ? 'Search Application' : ''" icon="pi pi-search" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2" /> 
                        <Divider />
                        
                    </div>  
                </div> 
            </div> 
            
            <div v-if="userData.role_id == 25"  class="col-12 sm:col-12 md:col-4 lg:col-4">
                <div v-if="loading" class="grid">
                    <div class="col-12 sm:col-12 md:col-12 lg:col-12" >
                        <div class="custom-skeleton p-4">
                            <div class="card widget-social ">
                                <div class=" justify-content-center align-items-center p-2">
                                    <div class="info  flex-column">
                                        <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                        <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                    </div>
                                </div>
                                <div class="stats flex justify-content-between">
                                    <div class="left flex flex-column ">
                                        <span class="title">
                                            <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                            <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                        </span>
                                    </div>
                                    <div class="left flex flex-column ">
                                        <span class="title">
                                             <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                            <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                        </span>
                                    </div>
                                    <div class="right flex flex-column ">
                                        <span class="title">
                                             <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                            <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!loading" class="grid">
                    <div class="col-12 sm:col-12 md:col-12 lg:col-12"  v-for="(row,index) in hostels" :key="index">
                        <div class="card widget-social ">
                            <div class="flex justify-content-center align-items-center p-2">
                                <div class="info flex flex-column">
                                    <span class="value">{{ row.hostel }}</span>
                                </div>
                            </div>
                            <div class="stats flex justify-content-between">
                                <div class="left flex flex-column ">
                                    <span class="title mb-2 ">Students</span>
                                    <span class="value fs-large">{{row.total_students}}</span>
                                </div>
                                <div class="left flex flex-column ">
                                    <span class="title mb-2 ">HOs &amp; Corps</span>
                                    <span class="value fs-large">{{row.total_ho_core}}</span>
                                </div>
                                <div class="left flex flex-column ">
                                    <span class="title mb-2">Occupied rooms</span>
                                    <span class="value fs-large">{{row.total_rooms}}</span>
                                </div>
                                <div class="right flex flex-column ">
                                    <span class="title mb-2">Today IN/OUT</span>
                                    <span class="value fs-large">{{row.total_in}} / {{row.total_out}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userData.role_id == 25" class="col-12 sm:col-12 md:col-4 lg:col-4">
                <div v-if="loading" class="grid">
                    <div class="col-12 sm:col-12 md:col-12 lg:col-12" >
                        <div class="custom-skeleton p-4">
                            <div class="card widget-social ">
                                <div class=" justify-content-center align-items-center p-2">
                                    <div class="info  flex-column">
                                        <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                        <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                    </div>
                                </div>
                                <div class="stats flex justify-content-between">
                                    <div class="left flex flex-column ">
                                        <span class="title">
                                            <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                            <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                        </span>
                                    </div>
                                    <div class="left flex flex-column ">
                                        <span class="title">
                                             <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                            <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                        </span>
                                    </div>
                                    <div class="right flex flex-column ">
                                        <span class="title">
                                             <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                            <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!loading" class="grid">
                    <div class="col-12 sm:col-12 md:col-12 lg:col-12">
                        <div class="card widget-social ">
                            <div class="flex justify-content-center align-items-center p-2">
                                <div class="info flex flex-column">
                                    <span class="value">Leave Statistics of Students</span>
                                </div>
                            </div>
                            <div class="stats flex justify-content-between">
                                <div class="left flex flex-column ">
                                    <span class="title mb-1">
                                        <Tag :class="'mr-2 status-Active'" icon="pi pi-info-circle" :severity="'warning'" :value="'Pending'"></Tag>
                                    </span>
                                    <span class="value fs-large">{{leaveStatistics.total_pending}}</span>
                                </div>
                                <div class="left flex flex-column">
                                    <span class="title mb-1">
                                        <Tag :class="'mr-2 status-Active'" icon="pi pi-check-circle" :severity="'success'" :value="'Approved'"></Tag>
                                    </span>
                                    <span class="value fs-large">{{leaveStatistics.total_approved}}</span>
                                </div>
                                <div class="right flex flex-column ">
                                    <span class="title mb-1">
                                        <Tag :class="'mr-2 status-Blocked'" icon="pi pi-times-circle" :severity="'danger'" :value="'Rejected'"></Tag>
                                    </span>
                                    <span class="value fs-large">{{leaveStatistics.total_rejected}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!loading" class="grid">
                    <div class="col-12 sm:col-12 md:col-12 lg:col-12">
                        <div class="card widget-social ">
                            <div class="flex justify-content-center align-items-center p-2">
                                <div class="info flex flex-column">
                                    <span class="value">Leave Statistics of HOs &amp; Corps</span>
                                </div>
                            </div>
                            <div class="stats flex justify-content-between">
                                <div class="left flex flex-column ">
                                    <span class="title mb-1">
                                        <Tag :class="'mr-2 status-Active'" icon="pi pi-info-circle" :severity="'warning'" :value="'Pending'"></Tag>
                                    </span>
                                    <span class="value fs-large">{{leaveStatisticsHoCore.total_pending}}</span>
                                </div>
                                <div class="left flex flex-column">
                                    <span class="title mb-1">
                                        <Tag :class="'mr-2 status-Active'" icon="pi pi-check-circle" :severity="'success'" :value="'Approved'"></Tag>
                                    </span>
                                    <span class="value fs-large">{{leaveStatisticsHoCore.total_approved}}</span>
                                </div>
                                <div class="right flex flex-column ">
                                    <span class="title mb-1">
                                        <Tag :class="'mr-2 status-Blocked'" icon="pi pi-times-circle" :severity="'danger'" :value="'Rejected'"></Tag>
                                    </span>
                                    <span class="value fs-large">{{leaveStatisticsHoCore.total_rejected}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            
            
            
		<!-- <div class="col-12 sm:col-6 md:col-3 lg:col-3">
            <div class="card height-90">  
                <div class="card-header"> 
                        <h5 class="widget-statistics-heading-h5">Today Activity (100) </h5> 
                   </div>  
                <Divider />
                <div class="card-body">
                    <ul class="widget-list dashboard-employee-list">
                        <li class="flex align-items-center py-3">
                            <div class="person-item pointer flex align-items-center">
                                <img :src="filesUrl+'defaults/default.png'" class="tableProfileImg mr-2" /> 
                                <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                                    <div><strong>MMM KKKK</strong></div>
                                    <small class="muted-text">MBBS 4th year</small>
                                </div>
                            </div>
                            <span class="person-tag indigo-bgcolor p-1 fs-small" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">10:50:50</span> 
                        </li>  
                    </ul>
                    <div><h6 class="text-center pt-5 pb-5">No Employee Found! Create One</h6></div>
                </div> 
            </div>
        </div>  -->
	
        <LeaveApplicationStatus v-on:formClose="closeForm" v-if="showAppStatus" :recordId="selectedRecordId" :showAppStatus="true" />
        <LeaveApplicationStatusHoCore v-on:formClose="closeFormHoCore" v-if="showAppStatusHoCore" :recordId="selectedRecordId" :showAppStatusHoCore="true" />
	</div>
 </template>

<script>  

import DigitalClock  from "../../components/DigitalClock/DigitalClock.vue";
import LeaveApplicationStatus from '../../components/LeaveApplications/LeaveApplicationStatus.vue';
import LeaveApplicationStatusHoCore from '../../components/LeaveApplications/LeaveApplicationStatusHoCore.vue';


export default {
	data() {
		return { 
			applicationId:'',
			showAppStatus:false,
            showAppStatusHoCore:false,
			selectedRecordId: 0,  

            hostels:[],
            leaveStatistics:[],leaveStatisticsHoCore:[],
            loading: true,
		}
	}, 
	created() { 
	},
    components:{ 
		DigitalClock,
		LeaveApplicationStatus,
        LeaveApplicationStatusHoCore
        
    },
	mounted() { 
        this.dashboardData();
    
	},
	watch: { 
	},
	methods: {  
        dashboardData(){
            var Vthis = this;
            this.axios.post('dashboardData')
            .then( (response) => {  
                // console.log(response.data);
                Vthis.hostels = response.data.hostel;
                Vthis.leaveStatistics =  response.data.leaveStatistics;
                Vthis.leaveStatisticsHoCore =  response.data.leaveStatisticsHoCore;
                Vthis.loading = false;
            })
            .catch((error) => {    
                let message = Vthis.customError(error.response); 
                Vthis.showMessage(message,'error');  
            }).finally(() => { 
            })  
        },
		searchApplication(){
			var Vthis = this;
			if(this.applicationId){
				this.axios.post('getAllHostelApplicationsStatus',{id:this.applicationId})
				.then( (response) => {  
                    
					if(response.data.data.length){
                        
                        this.selectedRecordId = this.applicationId;
                        
                        if(response.data.data[0].std_id > 9869999)
                        {
                            //alert("Hello World" + JSON.stringify(response.data.data[0].std_id, null, 2));
						    this.showAppStatusHoCore = true;
                        }
                        else
                        {
						    this.showAppStatus= true;
                        }
					}else{
						alert('No record found');
						// let message =Vthis.customError('Application not found.'); 
						Vthis.showMessage('App Not Found','error'); 
					}
				}).catch((error) => {    
					let message = Vthis.customError(error.response); 
					Vthis.showMessage(message,'error');  
				}).finally(() => { 
					
				})  
			}
		},
		closeForm(){ 
			this.showAppStatus = false;
           
		}, 
        closeFormHoCore(){ 
            this.showAppStatusHoCore = false;
		}, 
	},
	computed: { 
	}
}
</script>

<style lang="scss" scoped>

</style>
