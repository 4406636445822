<template>
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0"> {{ module_name }}</h3> 
                    </div>
                    <Divider class="mt-0" />
                    <TabView  @tab-click="loadTargetedTab($event)" class="tabview-custom" v-model:activeIndex="activeTab" :scrollable="true">
                         <TabPanel header="Student Information">
                            <template #header>
                                &nbsp; <i class="pi pi-info-circle"></i>
                            </template>
<div class="grid" style="font-size:16px;">
    <div class="col-12 md:col-6">
        <div class="card height-100 widget-topsearchs">
            <div class="card-header">
                <div>
                    <img v-if="data.profile_pic" :alt="data.name"
                        :src="studentFiles + 'students/' + data.std_id + '/' + data.profile_pic"
                        style="width:80px; height: 80px;" />
                    <img v-if="!data.profile_pic" :alt="data.name" :src="filesUrl + 'defaults/default.png'"
                        style="width:80px; height: 80px;" />

                </div>
                <div>
                    <h5>{{ data.name }} - {{ data.student_id }}</h5>
                    {{ data.class }}<br />
                    Room No: {{ data.room_no }}
                </div>

            </div>
            <div class="flex justify-content-between item"><span>Roll No: </span><span
                    class="value type-green">{{ data.system_rollno_prefix }}-{{ data.system_rollno }}</span></div>
            <div class="flex justify-content-between item"><span>Start Date: </span><span
                    class="value type-green">{{ (data.leave_start_date) }}</span></div>
            <div class="flex justify-content-between item"><span>End Date:</span><span
                    class="value type-green">{{ (data.leave_end_date) }}</span></div>

            <div class="flex justify-content-between item"><span>Type:</span><span
                    class="value type-yellow">{{ data.leave_type }}</span></div>
            <div class="flex justify-content-between item"><span>Status:</span><span class="value type-yellow">
                    <Tag v-if="data.leave_status == 0" :class="'mr-2 p-2 fs-large status-Active'"
                        icon="pi pi-info-circle" :severity="'warning'" :value="'Pending'"></Tag>
                    <Tag v-if="data.leave_status == 1" :class="'mr-2 p-2 fs-large status-Active'"
                        icon="pi pi-check-circle" :severity="'success'" :value="'Approved'"></Tag>
                    <Tag v-if="data.leave_status == 2" :class="'mr-2 p-2 fs-large status-Blocked'"
                        icon="pi pi-times-circle" :severity="'danger'" :value="'Rejected'"></Tag>
                </span></div>
            <div class="flex justify-content-between item"><span>File:</span><span class="value type-pink">
                    <span v-for="(file, index) in files" :key="index">
                        <Tag v-if="file.file_name" @click="downloadFile(file.file_name)" :class="'mr-2 status-Active'"
                            icon="pi pi-eye" :severity="'success'" :value="'View File'" style="cursor:pointer;"></Tag>
                    </span>
                    <!-- <Tag v-else :class="'mr-2 status-Blocked'" icon="pi pi-times" :severity="'danger'" :value="'No File'"></Tag> -->
                </span>
            </div>
            <div class="item" v-if="data.visiting_address">
                <b><span>Visiting Address:</span></b><br />
                <span class="value type-green">{{ data.visiting_address }}</span>
            </div>
            <div class="item" v-if="data.reason">
                <b><span>Reason:</span></b><br />
                <span class="value type-green">{{ data.reason }}</span>
            </div>
            <div class="item">
                <b><span>Description:</span></b><br />
                <span class="value type-green">{{ htmlparser_data(data.leave_application) }}</span>
            </div>
            <div v-if="data.out_by && data.out_date != null" class="flex justify-content-between item"><span>OUT
                    Detail:</span><span class="value type-green">{{ (data.out_date) }}</span></div>
            <div v-if="data.in_by && data.in_date != null" class="flex justify-content-between item"><span>IN
                    Detail:</span><span class="value type-green">{{ (data.in_date) }}</span></div>
        </div>
    </div>
    <div class="col-12 md:col-6">
        <div class="ticket myPrint">
            <table>
                <thead>
                    <tr>
                        <th colspan="2" style="text-align: center; border-top: 0px; margin: 5px;">
                            <img src="https://fs.cmhlmc.com/storage/library/assets/images/logo.png" alt="Logo"
                                style="width:80px; height: 80px;">
                        </th>
                        <!-- <th  style="float: right;border-top: 0px; margin: 5px;">
                                                        <img  v-if="data.profile_pic" :alt="data.name" :src="studentFiles+'students/'+data.std_id+'/'+data.profile_pic" style="width:80px; height: 80px;"/>
                                                        <img  v-if="!data.profile_pic" :alt="data.name" :src="filesUrl+'defaults/default.png'" style="width:80px; height: 80px;"/>
                                                    </th> -->
                    </tr>
                    <tr>
                        <th colspan="2" class="description">{{ data.name }} <br />{{ data.student_id }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="2" class="description">
                            <b>Class:</b> <br />{{ data.class }}
                        </td>
                    </tr>
                    <tr>
                        <td class="description">
                            <b>Roll No:</b> <br />{{ data.system_rollno_prefix }}-{{ data.system_rollno }}
                        </td>
                        <td class="description">
                            <b>Room No:</b> <br />{{ data.room_no }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="description">
                            <b>Leave Duration:</b>
                            <br />{{ (data.leave_start_date) }}<b> -TO- </b>{{ (data.leave_end_date) }}
                        </td>
                    </tr>
                    <tr v-if="data.out_by && data.out_date != null">
                        <td colspan="2" class="description">
                            <b>OUT DateTime:</b> <br />{{ (data.out_date) }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="description">
                            <b>Leave Type:</b> <br />{{ data.leave_type }}
                        </td>
                    </tr>

                    <tr>
                        <th colspan="2" class="description">
                            <span v-if="data.leave_status == 0">Pending</span>
                            <span v-if="data.leave_status == 1">Approved</span>
                            <span v-if="data.leave_status == 2">Rejected</span>
                        </th>
                    </tr>
                    <tr v-if="data.leave_status == 1">
                        <th colspan="2" class="description" style="text-align: center;font-size:10px">
                            <span>Note: You can only leave the college after submitting
                                this slip at the gate</span>
                        </th>
                    </tr>

                </tbody>
                <tfoot>

                    <tr>
                        <td colspan="2" class="description" style="text-align: center;">
                            <b>CMH Lahore Medical College & IOD<br />
                                Abdur Rehman Road, Lahore Cantt, Pakistan<br />
                                Tel:+92 42-36605550 - Tel:+92 42-36605551</b>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="description" style="text-align: center;">
                            <vue3-barcode :value="data.app_id" :display-value="false" :height="50" />
                        </td>
                    </tr>


                </tfoot>

            </table>
            <Button v-if="data.leave_status != 0" type="button" label="Print" icon="pi pi-print" @click="printClick"
                class="mt-3 hidden-print"></button>

        </div>


    </div>
</div>

<!-- <div class="grid">
                                <div class="col-6 col-offset-3">
                                    <div class="box">6</div>
                                </div>
                            </div> -->
</TabPanel>
</TabView>

<Divider />
<div class="col-12 lg:col-12">
    <Toolbar class="pl-0 pr-0">
        <template v-slot:start v-if="!loading">
                                <span v-if="data.leave_type == 'Out Pass'">
                                    <Button type="submit" v-if="data.in_out_status == 'out'" @click="inOutPassStatus(data.app_id, 'in')"
                                    label="IN"  icon="pi pi-chevron-circle-down" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                    <Button type="button" v-if="data.in_out_status == NULL" @click="inOutPassStatus(data.app_id, 'out')"
                                    label="OUT" icon="pi pi-chevron-circle-up" class="p-right p-button-raised p-button-lg p-button-danger mr-2"/>
                                </span>
                                <span v-else>
                                    <span v-if="data.leave_status == 1">
                                        
                                            <!-- <div class="card flex justify-content-center">
                                                <label for="sms_type" style="align-content: center;margin-right: 10px;font-size: medium;font-weight: 500;">Send SMS to</label>
                                                <SelectButton v-model="sms_value" :options="sms_options" aria-labelledby="basic"  id="sms_type"/>
                                            </div> -->

                                            <Button v-if="data.in_date == null && data.out_date != null" type="submit" @click="inOutPassStatus(data.app_id, 'in')"
                                            label="IN"  icon="pi pi-chevron-circle-down" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                            
                                            <Button v-if="data.out_date == null" type="button" @click="inOutPassStatus(data.app_id, 'out', 'no')"
                                            label="OUT" icon="pi pi-chevron-circle-up" class="p-right p-button-raised p-button-lg p-button-danger mr-2"/>

                                            <Button v-if="data.out_date == null" type="button" @click="inOutPassStatus(data.app_id, 'out', 'print')"
                                            label="OUT & Print" icon="pi pi-chevron-circle-up" class="p-right p-button-raised p-button-lg p-button-success mr-2"/>
                                      
                                           
                                    </span>
                                    <span v-if="data.leave_status == 0">
                                        <p class="fs-large">Pending for approval.</p>
                                    </span>
                                </span>

                            </template>
        <template v-slot:center v-if="!loading">

                            </template>
        <template v-slot:end>

                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template>
    </Toolbar>
</div>
</div>

</Sidebar>

<ConfirmDialog group="dialog" />
</BlockUI>
</template>
<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';

export default {
    data() {
        return {
            module_name: 'Student IN/OUT',
            isSaving: false,
            saveAndClose: false,
            recId: 0,
            loadingComponent: true,
            formVisible: false,
            data: [],
            roomHistory: [],
            columns: null,
            filters: null,
            loading: true,
            files: [],
            sms_value: 'Both',
            sms_options: ['Both']
        }
    },
    props: {
        showAppStatus: {
            type: Boolean,
            required: true,
        },
        recordId: {
            type: Number,
        },
    },
    created() {

    },
    computed: {

    },
    mounted() {
        this.formVisible = this.showAppStatus;
        this.recId = this.recordId;
        this.viewRecord();
        // window.print();
    },
    methods: {
        printClick() {

            // window.print();
            var mywindow = window.open('', 'PRINT', 'height=800,width=900');
            mywindow.document.write('<html><head><title>' + document.title + '</title>');
            mywindow.document.write('<style> \
                    td, th, tr, table { \
                        border-top: 1px solid black;\
                        border-collapse: collapse; \
                    }td{\
                        font-size: 12px;\
                    } .ticket {\
                        margin-top:10px ;\
                    }\ img {\
                        max-width: 80px;\
                        width: 80px;\
                    }\
                    @media print {\
                        .hidden-print,\
                        .hidden-print * {\
                            display: none !important;\
                        }\
                    }\
                </style>');

            mywindow.document.write('</head><body>');
            // mywindow.document.write('<h1>' + document.title  + '</h1>');
            mywindow.document.write(document.getElementsByClassName('myPrint')[0].innerHTML);
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            // mywindow.close();
        },
        loadTargetedTab($event) {
            this.showTab1 = false;
            this.showTab2 = false;
            let i = 1;
            do {
                if ($event.index === 1) {
                    this.showTab1 = true;
                }
                i += 1;
            } while (i < 6);
        },
        closeForm() {
            this.$emit('formClose', true);
        },
        viewRecord() {
            var Vthis = this;
            this.loading = true;
            this.axios.post('getAllHostelApplications', { id: this.recId })
                .then((response) => {
                    this.data = response.data.data[0];
                    this.files = response.data.files;
                    this.loading = false;
                }).catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                }).finally(() => {

                })
        },
        clearFilter() {
            this.initFilters();
        },
        inOutPassStatus(app_id, status, p) {
            var Vthis = this;
            var sms_type = Vthis.sms_value;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirmation',
                message: 'Are you sure you want to change status ?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    Vthis.loading = true;
                    Vthis.axios.post('inOutStatus', { id: app_id, in_out_status: status, sms_type: sms_type, is_ho: 'n' })
                        .then(() => {
                            Vthis.showMessage('Status Changed successfully', 'success');
                        }).catch((error) => {
                            let message = Vthis.customError(error.response);
                            Vthis.showMessage(message, 'error');
                        }).finally(() => {
                            Vthis.loading = false;
                            this.viewRecord();
                            this.emitter.emit('Reload', true);
                            if (p == 'print') {
                                setTimeout(() => {
                                    Vthis.printClick();
                                }, 2000)
                            }

                        })
                }, reject: () => {
                    // this.loading = false;
                }
            });
        },

    },
    watch: {

    }
}

</script>

<style scoped>
    td, th, tr, table {
        border-top: 1px solid black;
        border-collapse: collapse;
    }
    td{
        font-size: 12px;
    }
    .ticket {
        margin-top:10px ;
    }
    .ticket img {
        max-width: 80px;
        width: 80px;
    }
    @media print {
        .hidden-print,
        .hidden-print * {
            display: none !important;
        }
    }
</style>